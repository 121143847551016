"use client";
import React, { useState } from "react";

interface QueryProps {
  name: string;
  email: string;
  message: string;
}

const Contact = () => {
  const [formStatus, setFormStatus] = useState<boolean>(false);
  const [query, setQuery] = useState<QueryProps>({
    name: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState<string | null>(null); // Added state for error

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setQuery((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormStatus(true);
    try {
      const response = await fetch(
        "https://getform.io/f/ac82be5b-f556-4968-b62e-872a9a3c3b8d",
        {
          method: "POST",
          // Convert the query object to a string representation
          body: new URLSearchParams(
            Object.entries(query).map(([key, value]) => [key, value.toString()])
          ).toString(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // if (!response.ok) {
      //   throw new Error(`HTTP error! Status: ${response.status}`);
      // }

      console.log(await response.json());

      setQuery({ name: "", email: "", message: "" });
    } catch (error) {
      // Handle the error
      if (error instanceof Error) {
        setError(`Failed to send message: ${error.message}`);
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <section id="contact" className="relative bg-dark-back" aria-live="polite">
      <h2 className="text-dark-txt text-center font-bold tracking-tightTwo">
        Get In Touch
      </h2>
      <div className="ccr-line bg-felicis-border overflow-hidden"></div>
      <div className="max-w-3xl mx-auto">
        <p
          className="text-xl mb-8 leading-9 tracking-tightOne"
          data-aos="zoom-y-out"
          data-aos-delay="150"
          style={{ textAlign: "center", color: "#98A2B3" }}
        >
          {!formStatus
            ? "A new project is an opportunity to create something unique. Tell us about your idea."
            : "Thank You! Your message has been sent."}
        </p>
      </div>

      {!formStatus ? (
        <form
          className="contactForm items-center flex-col flex w-6/12"
          onSubmit={handleSubmit}
        >
          <label htmlFor="name" className="w-full">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Your name"
              required
              value={query.name}
              onChange={handleChange}
              className="w-full max-w-full text-dark-txt"
              autoComplete="name"
            />
          </label>
          <label htmlFor="email" className="w-full">
            <input
              type="email"
              name="email"
              id="email"
              required
              placeholder="Please enter a valid email"
              value={query.email}
              onChange={handleChange}
              className="w-full max-w-full text-dark-txt"
              autoComplete="email"
            />
          </label>
          <label htmlFor="message" className="w-full">
            <textarea
              name="message"
              id="message"
              rows={2}
              placeholder="How can we help you?"
              required
              value={query.message}
              onChange={handleChange}
              className="w-full max-w-full text-dark-txt"
              autoComplete="off"
            />
          </label>
          <button
            aria-label="Send message"
            className="relative font-semibold justify-center items-center inline-flex h-16 w-auto text-dark-txt align-middle"
            type="submit"
          >
            Send Message
          </button>
        </form>
      ) : (
        <p
          className="relative font-semibold justify-center items-center inline-flex h-16 w-auto text-dark-txt align-middle"
          style={{ display: "flex" }}
        >
          Submitted
        </p>
      )}
    </section>
  );
};

export default Contact;
